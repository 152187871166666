import type { RawLocation } from 'vue-router';
import { useRouter, useContext } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUiNotification } from '~/composables/useUiNotification';

type LinkGroup = { title: string, items: LinkGroupItem[] };
type LinkGroupItem = { label: string, link?: RawLocation, listeners?: Record<string, () => (Promise<void> | void)> };

export const useSidebarLinkGroups = () => {
  const { localeRoute } = useContext();
  const { logout } = useUser();
  const { clear } = useCart();
  const { send: sendNotification } = useUiNotification();
  const { app: { i18n }, $config } = useContext();

  const router = useRouter();
  let sidebarLinkGroups : LinkGroup[] = [
    {
      title: 'Personal details',
      items: [
        {
          label: 'My profile',
          link: { name: 'customer-my-profile' },
        },
        {
          label: 'Carta Unica',
          link: { name: 'customer-carta-unica' },
        },
        {
          label: 'Addresses details',
          link: { name: 'customer-addresses-details' },
        },
        {
          label: 'My newsletter',
          link: { name: 'customer-my-newsletter' },
        },
        {
          label: 'My wishlist',
          link: { name: 'customer-my-wishlist' },
        },
        {
          label: 'My Appointments',
          link: { name: 'customer-my-appointments' },
        },
      ],
    },
    {
      title: 'Order details',
      items: [
        {
          label: 'Order history',
          link: { name: 'customer-order-history' },
        },
        {
          label: 'Log out',
          listeners: {
            click: async () => {
              await logout({});
              await clear({});
              await router.push(localeRoute({ name: 'home' }));

              sendNotification({
                id: Symbol('logout_success'),
                message: i18n.t('You have been logged out.') as string,
                persist: false,
                title: 'Logout success',
                type: 'success',
                icon: 'success',
              });
            },
          },
        },
      ],
    },
  ];

  // don't show booking module in my account when the module is disabled in .env
  if (!$config.enableBookingModule) {
    sidebarLinkGroups = sidebarLinkGroups.map(group => {
      return {
        ...group,
        items: group.items.filter(item => item.label !== 'My Appointments')
      };
    });
  }

  return { sidebarLinkGroups };
};
