import {Ref, ref, useContext} from '@nuxtjs/composition-api';
import {Logger} from '~/helpers/logger';
import getCardBalanceQuery from "~/bold/stores/graphql/loyaltyProgram/getCardBalance.gql";
import getCardNumberQuery from "~/bold/stores/graphql/loyaltyProgram/getCardNumber.gql";
import activateExistingCardQuery from "~/bold/stores/graphql/loyaltyProgram/activateExistingCard.gql";
import registerNewAccountGql from "~/bold/stores/graphql/loyaltyProgram/registerNewAccount.gql";
import customerRewardsGql from "~/bold/stores/graphql/loyaltyProgram/customerRewards.gql";
import applyCustomerRewardToCartGql from "~/bold/stores/graphql/loyaltyProgram/applyCustomerRewardToCart.gql";
import removeCustomerRewardFromCartGql from "~/bold/stores/graphql/loyaltyProgram/removeCustomerRewardFromCart.gql";
import {useCartStore} from '~/modules/checkout/stores/cart';

import {
    useLoyaltyProgramErrors,
    useLoyaltyProgramInterface,
} from './useLoyaltyProgram';

/**
 * Make GraphQl call to get card balance
 */
export function loyaltyCard(): useLoyaltyProgramInterface {
    const {app} = useContext();
    const cartStore = useCartStore();
    const {state} = app.context.$vsf.$magento.config;

    const cartToken = state.getCartId();


    const loading: Ref<boolean> = ref(false);
    const error: Ref<useLoyaltyProgramErrors> = ref({
        load: null,
    });

    const balance: Ref = ref(null);
    const cardNumber: Ref = ref(null);

    const getCardBalance = async () => {
        if (balance.value === null) {
            try {
                loading.value = true;
                const {data} = await app.context.$vsf.$magento.api.customQuery({query: getCardBalanceQuery})

                Logger.debug('[balance data]:', {data});

                balance.value = data?.efidelityCustomerBalance?.balance ?? null;

                error.value.load = null;
            } catch (err) {
                error.value.load = err;
                balance.value = null;
                console.error('getBalance', err);
            } finally {
                loading.value = false;
            }
        }
    };

    /**
     * Retrieves the card number for the user.
     * @async
     * @function getCardNumber
     * @returns {Promise<void>} A Promise that resolves when the card number is retrieved.
     */
    const getCardNumber = async () => {
        if (cardNumber.value === null) {
            try {
                loading.value = true;
                const {data} = await app.context.$vsf.$magento.api.customQuery({query: getCardNumberQuery})

                if (data) {
                    Logger.debug('[cardnumber data]:', {data});
                }
                const attributes = data?.customer?.custom_attributes;
                if (Array.isArray(attributes)) {
                    cardNumber.value = attributes.find(attr => attr.code === 'efidelity_card_number')?.value ?? null;
                } else {
                    cardNumber.value = null;
                }

                error.value.load = null;
            } catch (err) {
                error.value.load = err;
                cardNumber.value = null;
                console.error('getCardNumber', err);
            } finally {
                loading.value = false;
            }
        }
    };

    /**
     * Link customer account to external loyalty card
     *
     * @param customerData
     */
    const linkExistingLoyaltyCard = async (customerData) => {
        try {
            loading.value = true;

            const {data} = await app.$vsf.$magento.api.customQuery({
                // @ts-ignore
                query: activateExistingCardQuery,
                queryVariables: {
                    cardInput: {
                        email: customerData.email,
                        firstname: customerData.firstName,
                        lastname: customerData.lastName,
                        card_number: customerData.cardNumberInput,
                        fiscal_code: customerData.fiscalCode
                    }
                }
            });

            Logger.debug('[card link data]:', {data});

            error.value.load = null;

            return data;
        } catch (err) {
            error.value.load = err;
        } finally {
            loading.value = false;
        }
    };

    /**
     * Register new loyalty card account
     *
     * @param customerData
     */
    const registerNewAccount = async (customerData) => {
        try {
            loading.value = true;

            const {data, errors} = await app.$vsf.$magento.api.customQuery({
                // @ts-ignore
                query: registerNewAccountGql,
                queryVariables: {
                    accountInput: {
                        email: customerData.email,
                        firstname: customerData.firstName,
                        lastname: customerData.lastName,
                        fiscal_code: customerData.fiscalCode,
                        privacy1: customerData.privacyOne,
                        privacy2: customerData.privacyTwo,
                        privacy3: customerData.privacyThree,
                    }
                }
            });

            Logger.debug('[register loyalty account]:', {data});

            error.value.load = null;

            return {data, errors};
        } catch (err) {
            error.value.load = err;
            cardNumber.value = null;
            console.error('registerLoyaltyAccount', err);
        } finally {
            loading.value = false;
        }
    };

    /**
     * Get available loyalty rewards for customer
     */
    const getCustomerRewards = async () => {
        try {
            loading.value = true;

            const {data, errors} = await app.$vsf.$magento.api.customQuery({
                // @ts-ignore
                query: customerRewardsGql,
            });

            Logger.debug('[get loyalty rewards]:', {data});

            error.value.load = null;

            return {data, errors};
        } catch (err) {
            error.value.load = err;
            cardNumber.value = null;
            console.error('getCustomerRewards', err);
        } finally {
            loading.value = false;
        }
    };

    /**
     * Apply loyalty reward to cart
     *
     * @param rewardCode
     */
    const applyCustomerRewardToCart = async (rewardCode: String) => {
        try {
            loading.value = true;

            const {data, errors} = await app.$vsf.$magento.api.customQuery({
                // @ts-ignore
                query: applyCustomerRewardToCartGql,
                queryVariables: {
                    rewardInput: {
                        cart_id: cartToken,
                        reward_code: rewardCode
                    }
                }
            });

            Logger.debug('[apply loyalty reward code data]:', {data});

            error.value.load = null;

            // @ts-ignore
            if (data?.efidelityApplyRewardToCart?.cart) {
                // @ts-ignore
                const cart = data.efidelityApplyRewardToCart.cart;

                cartStore.$patch((state) => {
                    state.cart = cart;
                });
            }

            return {errors};
        } catch (err) {
            error.value.load = err;
        } finally {
            loading.value = false;
        }
    };

    /**
     * Remove loyalty reward from cart
     */
    const removeCustomerRewardFromCart = async () => {
        try {
            loading.value = true;

            const {data, errors} = await app.$vsf.$magento.api.customQuery({
                // @ts-ignore
                query: removeCustomerRewardFromCartGql,
                queryVariables: {
                    rewardInput: {
                        cart_id: cartToken,
                    }
                }
            });

            Logger.debug('[remove loyalty reward code from cart data]:', {data});

            error.value.load = null;

            // @ts-ignore
            if (data?.efidelityRemoveRewardFromCart?.cart) {
                // @ts-ignore
                const cart = data.efidelityRemoveRewardFromCart.cart;

                cartStore.$patch((state) => {
                    state.cart = cart;
                });
            }

            return {errors};
        } catch (err) {
            error.value.load = err;
        } finally {
            loading.value = false;
        }
    };

    /**
     * Get reward that is active in cart
     */
    const getActiveRewardCode = () => {
        // @ts-ignore
        return cartStore?.cart?.applied_efidelity_reward?.code ?? null;
    }

    return {
        getCardBalance,
        getCardNumber,
        linkExistingLoyaltyCard,
        registerNewAccount,
        getCustomerRewards,
        applyCustomerRewardToCart,
        removeCustomerRewardFromCart,
        getActiveRewardCode,
        balance,
        cardNumber,
        loading,
        error,
    };
}

export * from './useLoyaltyProgram';

export default loyaltyCard;
