//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import SfCarouselItem from "@storefront-ui/vue/src/components/organisms/SfCarousel/_internal/SfCarouselItem.vue";
import BoldBullets from "~/bold/components/atoms/BoldBullets/BoldBullets.vue";
import Glide from "@glidejs/glide";
import SfArrow from "@storefront-ui/vue/src/components/atoms/SfArrow/SfArrow";
import {computed} from "@nuxtjs/composition-api";
import {mapMobileObserver} from "@/utilities/mobile-observer";

Vue.component("SfCarouselItem", SfCarouselItem);
export default {
  name: "Carousel",
  components: {
    SfArrow,
    BoldBullets,
  },
  props: {
    sliderOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const isMobile = computed(mapMobileObserver().isMobile.get);

    return {
      glide: null,
      isMobile: isMobile.value,
      defaultOptions: {
        type: "slider",
        rewind: true,
        autoplay: false,
        perView: 1,
        gap: 0,
      },
    };
  },
  computed: {
    mergedOptions() {
      return {
        ...this.defaultOptions,
        ...this.sliderOptions,
      };
    },
    numberOfPages() {
      return this.$slots.default
          ? this.$slots.default.filter((slot) => slot.tag).length
          : 0;
    },
    page() {
      if (this.glide) {
        return this.glide.index + 1;
      }
      return 1;
    },
    showButtons() {
      // if on mobile view then take pages per view from the mobile view, e.g. desktop per view is 5 and there are 5
      // pages the controls and bullets don't get shown. But on mobile the per view is 1.5. But because calculation
      // still uses 5 per view it does not show bullets on mobile.
      const { perView, breakpoints } = this.sliderOptions;
      const pagesPerView = (this.isMobile && breakpoints?.[800]?.perView) || perView;

      return this.numberOfPages > pagesPerView;
    },
  },
  mounted() {
    if (this.numberOfPages > 1) {
      this.$nextTick(() => {
        if (!this.$slots.default) return;
        const glide = new Glide(this.$refs.glide, this.mergedOptions);
        glide.mount();
        this.glide = glide;
      });
    }
  },
  methods: {
    go(direct) {
      if (!this.glide) return;
      switch (direct) {
        case "prev":
          this.glide.go("<");
          break;
        case "next":
          this.glide.go(">");
          break;
        default:
          this.glide.go(`=${direct}`);
          break;
      }
    },
  },
};
