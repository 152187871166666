//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
import SfImage from "@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue";
import SfCircleIcon from "@storefront-ui/vue/src/components/atoms/SfCircleIcon/SfCircleIcon.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import ProductQuantitySelector from "~/modules/catalog/product/components/ProductQuantitySelector.vue";
import SfLink from "@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue";
import SfProperty from "@storefront-ui/vue/src/components/atoms/SfProperty/SfProperty.vue";
import BoldPriceCartSidebar from "~/bold/components/atoms/BoldPrice/BoldPriceCartSidebar.vue";
import BoldDiscountLabelCartSidebar from "~/bold/components/atoms/BoldPrice/BoldDiscountLabelCartSidebar.vue";
import BoldPromoLabel from "~/bold/components/atoms/BoldPrice/BoldPromoLabel.vue";
import ProductPointsCart from "@/bold/components/organisms/ProductPointsCart.vue";
import LoyaltyProgram from "~/modules/customer/composables/LoyaltyProgram";
import {onMounted} from "@nuxtjs/composition-api";
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useUser } from '~/modules/customer/composables/useUser';

export default {
  name: "BoldCollectedProductCartSidebar",
  components: {
    ProductPointsCart,
    SfButton,
    SfIcon,
    SfImage,
    SfCircleIcon,
    ProductQuantitySelector,
    SfLink,
    SfProperty,
    BoldPriceCartSidebar,
    BoldDiscountLabelCartSidebar,
    BoldPromoLabel,
    SkeletonLoader
  },
  model: {
    prop: "qty",
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageWidth: {
      type: [Number, String],
      default: 140,
    },
    imageHeight: {
      type: [Number, String],
      default: 200,
    },
    title: {
      type: String,
      default: "",
    },
    hasCartPriceRule: {
      type: Boolean,
      default: false
    },
    promoMessage: {
      type: [String, Object],
      default: null,
    },
    promoInfo: {
      type: [String, Object],
      default: null,
    },
    finalPrice: {
      type: [String],
      default: null,
    },
    regularPrice: {
      type: [String],
      default: null,
    },
    specialPrice: {
      type: [String],
      default: null,
    },
    recommendedPrice: {
      type: [String],
      default: null,
    },
    lastKnownPrice: {
      type: [Number, String],
      default: null,
    },
    qty: {
      type: [Number, String],
      default: 1,
    },
    minQty: {
      type: Number,
      default: null,
    },
    maxQty: {
      type: Number,
      default: null,
    },
    link: {
      type: [String, Object],
      default: null,
    },
    hasRemove: {
      type: Boolean,
      default: true,
    },
    hasQtySelector: {
      type: Boolean,
      default: true,
    },
    hasMoreActions: {
      type: Boolean,
      default: true,
    },
    availableQty: {
      type: [String, Object],
      default: null,
    },
    loyaltyPoints: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    componentIs() {
      return this.link ? "SfLink" : "div";
    },
    quantity() {
      return typeof this.qty === "string" ? Number(this.qty) : this.qty;
    },
    regularPriceFloat() {
      return this.parsePrice(this.regularPrice);
    },
    specialPriceFloat() {
      return this.parsePrice(this.specialPrice);
    },
    hasPromo() {
      return !!this.specialPriceFloat && this.specialPriceFloat !== this.regularPriceFloat;
    },
    freePromo() {
      return this.promoMessage === "\nOmaggio!"
    }
  },
  methods: {
    removeHandler() {
      this.$emit("click:remove");
    },
    actionsHandler() {
      this.$emit("click:actions");
    },
    closeCartSidebar() {
      this.$emit("click:closeCartSidebar");
    },
    /**
     * Parses a price, which can deal with both numbers and formatted prices
     * Returns a float, with 2 decimals
     *
     * @param price
     * @returns {number}
     */
    parsePrice(price) {
      if (!price) return 0;
      const parsedPrice = Number.parseFloat(`${price}`
          .replace(/[^\d.,]/g, '')
          .replace(',','.'))
          .toFixed(2);
      return Number.parseFloat(parsedPrice);
    }
  },
  setup () {
    const { isAuthenticated } = useUser();
    const {
      getCardNumber,
      cardNumber,
      loading: cardNumberLoading
    } = LoyaltyProgram();

    onMounted(async () => {
      if (isAuthenticated.value) {
        await getCardNumber()
      }
    });

    return {
      cardNumber,
      cardNumberLoading,
      isAuthenticated
    }
  }
};
