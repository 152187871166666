//
//
//
//
//
//
//
//
//
//

import { ref } from "@nuxtjs/composition-api";
import { useSearchBar } from "~/composables/header/searchBar/searchBar";
const __sfc_main = {};
__sfc_main.props = {
  blok: Object
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    isSearchOpen
  } = useSearchBar();
  const headerShouldBeSticky = ref(false);
  return {
    headerShouldBeSticky
  };
};

export default __sfc_main;
