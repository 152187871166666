//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {defineComponent, ref} from '@nuxtjs/composition-api';

import { colorsValues as SF_COLORS } from "@storefront-ui/shared/variables/colors";
import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
import SfPrice from "@storefront-ui/vue/src/components/atoms/SfPrice/SfPrice.vue";
import SfRating from "@storefront-ui/vue/src/components/atoms/SfRating/SfRating.vue";
import SfImage from "@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue";
import SfCircleIcon from "@storefront-ui/vue/src/components/atoms/SfCircleIcon/SfCircleIcon.vue";
import SfBadge from "@storefront-ui/vue/src/components/atoms/SfBadge/SfBadge.vue";
import BoldSfButton from "~/bold/components/molecules/BoldSfButton.vue";
import SfColorPicker from "@storefront-ui/vue/src/components/molecules/SfColorPicker/SfColorPicker.vue";
import SfColor from "@storefront-ui/vue/src/components/atoms/SfColor/SfColor.vue";
import SvgImage from '~/components/General/SvgImage.vue';
import BoldPrice from "../atoms/BoldPrice/BoldPrice";
import BoldDiscountLabel from "~/bold/components/atoms/BoldPrice/BoldDiscountLabel.vue";
import { useUser } from '~/modules/customer/composables/useUser';
import BoldButtonAddToCart from "./Buttons/BoldButtonAddToCart";
import BoldButtonAddToCartUnavailable from "./Buttons/BoldButtonAddToCartUnavailable";
import { useGtm } from '~/composables/useGtm';

export default defineComponent({
  name: "BoldProductCard",
  components: {
    BoldButtonAddToCart,
    BoldPrice,
    BoldDiscountLabel,
    SfPrice,
    SvgImage,
    SfRating,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfBadge,
    BoldSfButton,
    SfColorPicker,
    SfColor,
    BoldButtonAddToCartUnavailable,
  },
  props: {
    product: {
      type: [Object, null],
      default: null,
    },
    image: {
      type: [Array, Object, String],
      default: "",
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    badgeLabel: {
      type: String,
      default: "",
    },
    badgeColor: {
      type: String,
      default: "",
    },
    colors: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Object],
      default: null,
    },
    /**
     * Link element tag
     * @deprecated will be removed in 1.0.0 use slot to replace content
     */
    linkTag: {
      type: String,
      default: undefined,
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    finalPrice: {
      type: [Number, String],
      default: null,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    recommendedPrice: {
      type: [Number, String],
      default: null,
    },
    lastKnownPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
    },
    isInWishlistIcon: {
      type: [String, Array],
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      deafult: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: "",
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    noAddToCartModalToggle: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {isAuthenticated} = useUser();
    const isAddingToCart = ref(false);
    const openColorPicker = ref(false);
    const {
      sendSelectItemEvent,
    } = useGtm();

    function toggleIsInWishlist() {
      this.$emit("click:wishlist", !this.isInWishlist);
    }

    function  onAddToCart(event) {
      event.preventDefault();
      this.isAddingToCart = true;
      setTimeout(() => {
        this.isAddingToCart = false;
      }, 1000);
      this.$emit("click:add-to-cart");
    }

    function handleSelectedColor(colorIndex) {
      if (this.colors.length > 0) {
        this.colors.map((color, i) => {
          if (colorIndex === i) {
            this.$emit("click:colors", color);
            this.openColorPicker = false;
          }
        });
      }
    }
    function toggleColorPicker() {
      this.openColorPicker = !this.openColorPicker;
    }

    const selectItem = () => {
      sendSelectItemEvent(props.product);
    }

    return {
      isAuthenticated,
      isAddingToCart,
      openColorPicker,
      toggleIsInWishlist,
      onAddToCart,
      handleSelectedColor,
      toggleColorPicker,
      selectItem,
    }
  },
  computed: {
    isSFColors() {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : "";
    },
    currentWishlistIcon() {
      return this.isInWishlist ? this.isInWishlistIcon : this.wishlistIcon;
    },
    showAddedToCartBadge() {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    ariaLabel() {
      return this.isInWishlist ? "Remove from wishlist" : "Add to wishlist";
    },
    wishlistIconClasses() {
      const defaultClass = "sf-button--pure wishlist-button";
      return `${defaultClass} ${this.isInWishlist ? "on-wishlist" : ""}`;
    },
    showBadge() {
      return this.colors.length > 5;
    },
    productUnavailable() {
      return this.product?.availability_code?.toLowerCase() === "n"
    },
  }
});
