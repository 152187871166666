//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
export default {
  name: "BoldBullets",
  inject: {
    components: {
      default: { SfButton },
    },
  },
  props: {
    total: {
      type: Number,
      default: 3,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  inactiveRight(total, current) {
    if (current >= total) {
      console.error(
        "Wrong value for the 'current' prop. This prop cannot be greater than or equal the 'total' value prop"
      );
      return total - 1;
    } else {
      return total - 1 - current;
    }
  },
  inactiveLeft(total, current) {
    if (current >= total) {
      console.error(
        "Wrong value for the 'current' prop. This prop cannot be greater than or equal the 'total' value prop"
      );
      return total - (total - 1) - 1;
    } else {
      return total - (total - 1 - current) - 1;
    }
  },
};
