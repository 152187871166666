
import {
  defineComponent, onMounted,
} from '@nuxtjs/composition-api';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

export default defineComponent({
  name: "PointsBalanceOverview",
  components: {
    SkeletonLoader,
  },
  props: {
    balance: {
      type: Number
    },
    cardNumber: {
      type: String,
      default: ''
    },
    cardNumberLoading: {
      type: Boolean,
      default: true
    },
  }
})
