//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "BoldPriceCartSidebar",
  props: {
    hasPromo: {
      type: Boolean,
      default: false,
    },
    freePromo: {
      type: Boolean,
      default: false
    },
    final: {
      type: [String, Number, Object],
      default: null,
    },
    regular: {
      type: [String, Number, Object],
      default: null,
    },
    special: {
      type: [String, Number],
      default: null,
    },
    lastKnown: {
      type: [String, Number],
      default: null,
    },
    showLabels: {
      type: Boolean,
      default: true
    },
    hasCartPriceRule: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    hasSpecialPrice() {
      return this.special && !this.splittedPrice(this.special).priceEqualsZero;
    },
    // Whether the special price is positive for unica or not, so we know to hide the listing price if it's not positive
    isSpecialPricePositive() {
      if (!this.hasSpecialPrice) return false;
      if (this.special === this.final) return false;

      const specialSplit = this.splittedPrice(this.special);
      const finalSplit = this.splittedPrice(this.final);

      const specialFloat = parseFloat(`${specialSplit.fullPrice}${specialSplit.decimalPrice}`);
      const finalFloat = parseFloat(`${finalSplit.fullPrice}${finalSplit.decimalPrice}`);

      return finalFloat > specialFloat;
    }
  },
  methods: {
    splittedPrice(price) {
      //[0] = full-price [1] = currencySymbol [2] = price without decimals [3] = delimiter [4] = decimals
      let symbolLeft = price.match("(.)(.+)([,\\.])(\\d{2})$")
      let symbolRight = price.match("(.+)([,\\.])(\\d{2})(.+)?(.)$")

      if (symbolLeft) {
        return {
          currencySymbol: symbolLeft[1],
          fullPrice: symbolLeft[2] + symbolLeft[3],
          decimalPrice: symbolLeft[4],
          priceEqualsZero: !(parseInt(symbolLeft[2] + symbolLeft[4]))
        }
      } else {
        return {
          currencySymbol: symbolRight[5],
          fullPrice: symbolRight[1] + symbolRight[2],
          decimalPrice: symbolRight[3],
          priceEqualsZero: !(parseInt(symbolRight[1] + symbolRight[3]))
        }
      }
    },
    stringHasDecimalPrice(price) {
      if (!price) {
        return null;
      }
      return this.splittedPrice(price).decimalPrice;
    },
  }
};
