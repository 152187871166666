export default `
   {
    efidelityCustomerRewards {
      code,
      threshold,
      discount_value,
      discount_label,
      name
    }
}
`;
