export default `{
    customer {
        custom_attributes {
            code
            ... on AttributeValue {
                value
            }
        }
    }
}`;
