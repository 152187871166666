//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onMounted, ref, watch } from '@nuxtjs/composition-api';
import { useCategorySearch } from '~/modules/catalog/category/composables/useCategorySearch';
import ProductsCarousel from "~/modules/catalog/product/components/ProductsCarousel";
import facetGetters from '~/modules/catalog/category/getters/facetGetters';
import {useFacet} from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';
import { useGtm } from '~/composables/useGtm';

export default {
  components: {ProductsCarousel},
  props: {
    // needs to be string because it needs to be encoded
    categoryId: {
      type: String,
      required: true,
    },
    categoryNamePrio: {
      type: String,
      required: false,
      default: null
    },
    desktopPerView: {
      type: Number,
      required: false,
      default: 5,
    },
    mobilePerView: {
      type: Number,
      required: false,
      default: 1,
    },
    desktopSlideWidth: {
      type: Number,
      required: false,
      default: 262,
    },
    desktopGap: {
      type: Number,
      required: false,
      default: 30,
    },
    desktopPeekAfter: {
      type: Number,
      required: false,
      default: 0,
    },
    mobilePeekBefore: {
      type: Number,
      required: false,
      default: 0,
    },
    noAddToCartModalToggle: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { error, search, result } = useCategorySearch();
    const categoryName = ref('');
    const products = ref([]);
    const { result: facetResult, search: facetSearch, loading } = useFacet();
    const { isAuthenticated } = useUser();
    const {
      sendViewItemListEvent,
    } = useGtm();
    const itemListId = ref("category_product_slider");
    const itemListName = ref("Category product slider");

    const loadCategory = async () => {
      const baseSearchQuery = {
        filters: {
          category_uid: {
            eq: btoa(props.categoryId)
          },
        },
      };

      // get category by id  and set category name as title of slider
      await search(baseSearchQuery);
      categoryName.value = result?.value[0]?.name
    }

    const loadProducts = async () => {
      // get products from category uid and set products of slider
      await facetSearch({ category_uid: result?.value[0]?.uid })
      products.value = await facetGetters.getProducts(facetResult.value).map(product => ({
        ...product,
        item_list_id: itemListId.value,
        item_list_name: itemListName.value,
      })) ?? [];
    }

    onMounted(async () => {
      await loadCategory();
      await loadProducts();
      sendViewItemListEvent(itemListId.value, itemListName.value, products.value, categoryName.value);
    });

    /**
     * Refresh the products when a user logs in or out, as the price may be different depending on this
     */
    watch(isAuthenticated, async (isAuthenticatedChange) => {
      await loadProducts();
    });

    return {
      error,
      loading,
      products,
      categoryName
    };
  },
};
