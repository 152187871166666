//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';

export default {
  name: "BoldPopup",
  components: {SvgImage},
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    containerClasses: {
      type: String,
      default:  'max-w-3xl'
    },
    titleClasses: {
      type: String,
      default:  ''
    },
    titleBorderClasses: {
      type: String,
      default:  ''
    }
  },
  setup(props, { emit }) {
    const fadeInActive = ref(false);
    const fadeOutActive = ref(false);
    const open = ref(false);

    function triggerClose() {
      fadeOutActive.value = true;

      setTimeout(() => {
        fadeOutActive.value = false;
        open.value = false
        emit("close");
      }, 400)
    }

    function triggerOpen() {
      open.value = true;
    }

    return {
      fadeInActive,
      fadeOutActive,
      open,
      triggerClose,
      triggerOpen
    }
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen && !this.open) {
        this.triggerOpen();
      } else if (!isOpen && this.open) {
        this.triggerClose();
      }
    }
  },
};
