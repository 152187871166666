//
//
//
//
//
//
//
//

import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import BoldButton from "~/bold/components/molecules/BoldButton.vue";
const __sfc_main = {};
__sfc_main.props = {
  blok: Object
};

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

__sfc_main.components = Object.assign({
  SfButton
}, __sfc_main.components);
export default __sfc_main;
