//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "BoldDiscountLabelCartSidebar",
  props: {
    // If the product has a recommended price, this is the recommended price, otherwise it is the price_range.minimum_price.final_price
    finalPrice: {
      type: [String],
      default: null,
    },
    // Also known as the prezzo consigliatio
    regularPrice: {
      type: [String],
      default: null,
    },
    // If the product has a recommended price, or if the regular price is larger than the final price, this is the final price
    specialPrice: {
      type: [String],
      default: null,
    },
    // Also known as farmadati price
    recommendedPrice: {
      type: [String],
      default: null,
    },
    // Whether to show the 'Promo' label (on desktop)
    showPromoLabel: {
      type: Boolean,
      default: true,
    },
    hasCartPriceRule: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    finalPriceFloat() {
      return this.parsePrice(this.finalPrice);
    },
    regularPriceFloat() {
      return this.parsePrice(this.regularPrice);
    },
    specialPriceFloat() {
      return this.parsePrice(this.specialPrice);
    },
    recommendedPriceFloat() {
      return this.parsePrice(this.recommendedPrice);
    },
    percentage() {
      // If there's no promo and no recommended price, there's nothing for us to calculate
      if (!this.hasPromo && !this.hasRecommendedPrice) return 0;

      // If we have a recommended price, and possibly a promo
      if (this.hasRecommendedPrice) {
        if (this.finalPriceFloat && this.specialPriceFloat) {
          return ((this.finalPriceFloat - this.specialPriceFloat) / this.finalPriceFloat * 100).toFixed();
        }
      }

      // If we have a promo and no recommended price
      if (this.hasPromo) {
        if (this.regularPriceFloat && this.specialPriceFloat) {
          return ((this.regularPriceFloat - this.specialPriceFloat) / this.regularPriceFloat * 100).toFixed();
        }
      }

      return 0;
    },
    hasPromo() {
      return !!this.specialPriceFloat && this.specialPriceFloat !== this.regularPriceFloat;
    },
    hasRecommendedPrice() {
      return !!this.recommendedPriceFloat;
    },
    // Don't show the discount if the percentage is 0, "0", "NaN", some other falsy type, or less than 0
    showDiscount() {
      if (!this.percentage) return false;
      if (this.percentage === "0" || this.percentage === "NaN") return false;
      return parseInt(this.percentage) > 0;
    },
  },
  methods: {
    /**
     * Parses a price, which can deal with both numbers and formatted prices
     * Returns a float, with 2 decimals
     *
     * @param price
     * @returns {number}
     */
    parsePrice(price) {
      if (!price) return 0;
      const parsedPrice = Number.parseFloat(`${price}`
        .replace(/[^\d.,]/g, '')
        .replace(',','.'))
        .toFixed(2);
      return Number.parseFloat(parsedPrice);
    },
  }
};

/**
 * Some example products and its desired percentage
 *
 * # 1
 * final: 20
 * regular: 10
 * special: 10
 * recommended: 20
 * promo: false
 * desired percentage: 50
 *
 * # 2
 * final: 20
 * regular: 10
 * special: 5
 * recommended: 20
 * promo: true
 * desired percentage: 75%
 *
 * # 3
 * final: 10
 * regular: 10
 * special: 0
 * recommended: null
 * promo: false
 * desired percentage: 0
 *
 * # 4
 * final: 5
 * regular: 10
 * special: 5
 * recommended: null
 * promo: true
 * desired percentage: 50
 */

