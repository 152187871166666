export default `
mutation efidelityRemoveRewardFromCart($rewardInput: RemoveEfidelityRewardFromCartInput){
    efidelityRemoveRewardFromCart(input: $rewardInput) {
      cart {
        id
        email
        is_virtual
        applied_coupons {
          code
        }
        applied_efidelity_reward {
           code
           threshold
        }
        wms_out_of_stock_items {
          items {
            sku
            error_message
            missing_qty
            available_qty
            requested_qty
            wms_error
          }
        }
        prices {
          subtotal_excluding_tax {
            value
          },
          subtotal_including_tax {
            value
          },
          applied_taxes {
            amount {
              value
            },
            label
          }
          discounts {
            amount {
              value
            },
            label
          }
          grand_total {
            value
          }
        }
        items {
          uid
          message
          bold_amasty_promo_info {
            is_promo_item
            needs_prefix
            prefix
          }
          product {
            uid
            __typename
            sku
            name
            stock_status
            only_x_left_in_stock
            is_promo_sale_only
            rating_summary
            thumbnail {
              url
              position
              disabled
              label
            }
            url_key
            url_rewrites {
              url
            }
            recommended_price
            last_known_price {
                 currency
                 value
            }
            mpbrand {
              value
            }
            bold_gallery_images {
              magento_roles,
              label,
              position,
              formats {
                mega,
                large,
                medium,
                small,
                tiny
              }
            }
            price_range {
              maximum_price {
                final_price {
                  currency
                  value
                }
                regular_price {
                  currency
                  value
                }
              }
              minimum_price {
                final_price {
                  currency
                  value
                }
                regular_price {
                  currency
                  value
                }
              }
            }
            efidelity_data {
              points
              rate
            }
            categories {
              uid
              name
              url_suffix
              url_path
              breadcrumbs {
                category_name,
                category_url_path
              }
            }
            review_count
            reviews {
              items {
                average_rating
                ratings_breakdown {
                  name
                  value
                }
              }
            }
          }
          prices {
            discounts {
              amount {
                value,
                currency
              }
              label
            }
            row_total {
              value
            }
            row_total_including_tax {
              value
            }
            total_item_discount {
              value
            }
          }
          quantity
          efidelity_data {
             points
             rate
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
            }
            configured_variant {
              sku
              thumbnail {
                url
              }
            }
          }
          ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
              values {
                id
                label
                price
                quantity
              }
            }
          }
          promotion {
            creative_name
            creative_slot
            promotion_name
            promotion_id
          }
          item_list {
            item_list_name
            item_list_id
          }
        }
        total_quantity
        shipping_addresses {
          firstname
          lastname
          street
          city
          company
          region {
            code
            region_id
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
          selected_shipping_method {
            carrier_code
            carrier_title
            method_code
            method_title
            amount {
              value
              currency
            }
            price_excl_tax {
              currency
              value
            }
            price_incl_tax {
              currency
              value
            }
          }
        }
        bold_selected_shipping_method {
          carrier_code
          carrier_title
          method_code
          method_title
          amount {
            value
            currency
          }
          price_excl_tax {
            currency
            value
          }
          price_incl_tax {
            currency
            value
          }
        }
        billing_address {
          firstname
          lastname
          street
          city
          company
          region {
            code
            region_id
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
        }
      }
    }
}
`;
