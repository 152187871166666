//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import BoldHeading from "../atoms/BoldHeading/BoldHeading";
export default {
  name: "BoldSection",
  components: {
    BoldHeading,
  },
  props: {
    titleHeading: {
      type: String,
      default: "",
    },
    subtitleHeading: {
      type: String,
      default: "",
    },
    levelHeading: {
      type: Number,
      default: 2,
    },
  },
};
