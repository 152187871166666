//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
export default {
  name: "Bar",
  components: {
    SfIcon,
    SfButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    back: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
};
