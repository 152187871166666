var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{class:[_vm.data.class, _vm.data.staticClass, 'flex items-center gap-2'],style:([_vm.data.style, _vm.data.staticStyle])},[_c(_vm.injections.components.SfButton,{tag:"component",staticClass:"h-9 w-9 bg-system-400 rounded-full flex items-center justify-center p-0",class:{ 'bg-body-800 text-system-400': _vm.props.disabled || Boolean(_vm.props.min !== null && _vm.props.qty <= _vm.props.min) },attrs:{"disabled":_vm.props.disabled || Boolean(_vm.props.min !== null && _vm.props.qty <= _vm.props.min),"data-testid":"decrease"},on:{"click":function($event){_vm.$options.handleInput(
        Number(_vm.props.qty) - 1,
        _vm.listeners,
        _vm.props.min,
        _vm.props.max
      )}}},[_c('svg',{staticClass:"h-6 w-6 text-white",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 30 30"}},[_c('path',{attrs:{"fill":"currentColor","d":"M20.85 16H9.15v-2h11.7v2z"}})])]),_vm._v(" "),_c(_vm.injections.components.SfInput,{tag:"component",staticClass:"sf-quantity-selector__input min-h-0 h-auto",attrs:{"type":"number","name":_vm.$options.uniqueKey(),"value":Number(_vm.props.qty),"disabled":_vm.props.disabled,"data-testid":"sf-quantity-selector input"},on:{"input":function($event){return _vm.$options.handleInput($event, _vm.listeners, _vm.props.min, _vm.props.max)},"blur":function($event){return _vm.$options.handleBlur(_vm.listeners)}}}),_vm._v(" "),_c(_vm.injections.components.SfButton,{tag:"component",staticClass:"h-9 w-9 bg-system-400 rounded-full flex items-center justify-center p-0",class:{ 'bg-body-800 text-system-400': _vm.props.disabled || Boolean(_vm.props.max !== null && _vm.props.qty >= _vm.props.max) },attrs:{"disabled":_vm.props.disabled || Boolean(_vm.props.max !== null && _vm.props.qty >= _vm.props.max),"data-testid":"increase"},on:{"click":function($event){_vm.$options.handleInput(
        Number(_vm.props.qty) + 1,
        _vm.listeners,
        _vm.props.min,
        _vm.props.max
      )}}},[_c('svg',{staticClass:"h-6 w-6 text-white",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 30 30"}},[_c('path',{attrs:{"fill":"currentColor","d":"M20.986 15.947h-4.938v4.982h-2.1v-4.981H9.014v-1.896h4.938v-4.98h2.1v4.98h4.934v1.896z"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }